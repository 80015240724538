export const parseFrontmatter = (content) => {
    // More flexible regex that handles different line endings and optional spaces
    const frontmatterRegex = /^[\r\n\s]*---[\r\n]+([\s\S]*?)[\r\n]+---[\r\n]+([\s\S]*)$/;
    const match = content.match(frontmatterRegex);

    if (!match) {
      console.log('No frontmatter found. Content starts with:', content.slice(0, 100));
      return {
        metadata: {},
        content: content
      };
    }
  
    const [, frontmatter, articleContent] = match;
    const metadata = {};
  
    // Parse the frontmatter into an object
    frontmatter.split(/[\r\n]+/).forEach(line => {
      // Skip empty lines
      if (!line.trim()) return;
      
      const colonIndex = line.indexOf(':');
      if (colonIndex === -1) return;
  
      const key = line.slice(0, colonIndex).trim();
      let value = line.slice(colonIndex + 1).trim();
  
      // Handle arrays in brackets
      if (value.startsWith('[') && value.endsWith(']')) {
        value = value
          .slice(1, -1)
          .split(',')
          .map(item => item.trim())
          .filter(item => item.length > 0);
      }
  
      metadata[key] = value;
    });
  
    console.log('Parsed metadata:', metadata);
    return {
      metadata,
      content: articleContent.trim()
    };
  };