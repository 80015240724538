import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Menu, X } from 'lucide-react';

const HeaderContainer = styled.header`
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const Logo = styled(Link)`
  font-size: 1.5rem;
  font-weight: bold;
  color: #3498db;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #2980b9;
  }
`;

const NavToggle = styled.button`
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  color: #3498db;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Nav = styled.nav`
  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const NavList = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const NavItem = styled.li`
  margin-left: 1.5rem;

  @media (max-width: 768px) {
    margin: 0;
  }
`;

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  color: #2c3e50;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
  text-align: center;

  &:hover {
    background-color: #3498db;
    color: #ffffff;
    transform: translateY(-3px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 768px) {
    padding: 1rem 2rem;
    border-bottom: 1px solid #ecf0f1;
  }
  @media (min-width: 768px) {
    min-height: 84px;
  }
`;

const DesktopNav = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileNav = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const navItems = [
    { to: "/", label: "Home" },
    { to: "/what-is-duns", label: "What is DUNS?" },
    { to: "/countries", label: "Countries" },
    { to: "/benefits", label: "Benefits of DUNS" },
    { to: "/all-articles", label: "Read more about DUNS" },
    { to: "/how-to-get", label: "How to get your DUNS" },
  ];

  return (
    <HeaderContainer>
      <HeaderContent>
        <Logo to="/">Global DUNS Number Finder</Logo>
        <DesktopNav>
          <NavList>
            {navItems.map((item, index) => (
              <NavItem key={index}>
                <NavLink to={item.to}>{item.label}</NavLink>
              </NavItem>
            ))}
          </NavList>
        </DesktopNav>
        <NavToggle onClick={toggleNav}>
          {isNavOpen ? <X size={24} /> : <Menu size={24} />}
        </NavToggle>
      </HeaderContent>
      <MobileNav>
        <Nav isOpen={isNavOpen}>
          <NavList>
            {navItems.map((item, index) => (
              <NavItem key={index}>
                <NavLink to={item.to} onClick={() => setIsNavOpen(false)}>
                  {item.label}
                </NavLink>
              </NavItem>
            ))}
          </NavList>
        </Nav>
      </MobileNav>
    </HeaderContainer>
  );
};

export default Header;