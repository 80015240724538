import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

// Sample data for articles
const articlesData = [
  {
    id: 'duns-for-google-app-developers',
    title: 'DUNS for Google App Developers: Why It Matters',
    route: '/articles/duns-for-google-app-developers'
  },
  {
    id: 'duns-and-dsa',
    title: 'DUNS and the Digital Services Act (DSA) in Europe',
    route: '/articles/duns-and-dsa'
  },
  {
    id: 'our-process',
    title: 'Our Process for DUNS Number Verification',
    route: '/articles/our-process'
  },
  {
    id: 'future-trends-duns-digital-services',
    title: 'Future Trends: DUNS, Digital Services, and the App Economy',
    route: '/articles/future-trends-duns-digital-services'
  },
  {
    id: 'digital-trust-app-developers',
    title: 'Enhancing Digital Trust for Developers',
    route: '/articles/digital-trust-app-developers'
  },
  {
    id: 'duns-and-global-digital-ecosystem',
    title: 'The Role of DUNS in the Global Digital Ecosystem',
    route: '/articles/duns-and-global-digital-ecosystem'
  },
  {
    id: 'eu-dsa-compliance-adobe',
    title: 'EU Digital Services Act Compliance for Adobe Commerce Developer',
    route: '/articles/eu-dsa-compliance-adobe'
  },
];

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const PageTitle = styled.h1`
  color: #3498db;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #34495e;
  margin-bottom: 2rem;
  text-align: center;
`;

const ArticlesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
`;

const ArticleLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  color: #2c3e50;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
  text-align: center;

  &:hover {
    background-color: #3498db;
    color: #ffffff;
    transform: translateY(-3px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const ArticlesPage = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>Articles on DUNS Number Benefits & Compliance | Global Business Identifier</title>
        <meta name="description" content="Explore our articles on the importance of DUNS numbers for digital businesses, app developers, and compliance with new regulations like the Digital Services Act in Europe." />
        <meta name="og:title" content="Articles on DUNS Number Benefits & Compliance | Global Business Identifier" />
        <meta name="twitter:title" content="Articles on DUNS Number Benefits & Compliance | Global Business Identifier" />
        <meta name="og:description" content="Explore our articles on the importance of DUNS numbers for digital businesses, app developers, and compliance with new regulations like the Digital Services Act in Europe." />
        <meta name="twitter:description" content="Explore our articles on the importance of DUNS numbers for digital businesses, app developers, and compliance with new regulations like the Digital Services Act in Europe." />
      </Helmet>
      <PageTitle>Articles & Insights</PageTitle>
      <Paragraph>
        Discover in-depth articles exploring the benefits of DUNS numbers for tech startups, app developers, and global digital businesses.
      </Paragraph>
      <ArticlesGrid>
        {articlesData.map(article => (
          <ArticleLink key={article.id} to={article.route}>
            {article.title}
          </ArticleLink>
        ))}
      </ArticlesGrid>
    </PageContainer>
  );
};

export default ArticlesPage;
