import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import * as S from './ArticleLayout';
import { parseFrontmatter } from '../utils/frontmatterParser';

const ArticlePage = () => {
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const loadArticle = async () => {
      try {
        const articleSlug = location.pathname.replace('/articles/', '');
        const response = await fetch(`/articles/${articleSlug}.md`);
        
        if (!response.ok) {
          throw new Error('Article not found');
        }
        
        const rawContent = await response.text();
        const { metadata, content } = parseFrontmatter(rawContent);
        
        // Ensure metadata is an object
        const validMetadata = metadata || {};
        
        setArticle({
          slug: articleSlug,
          content,
          metadata: validMetadata
        });
      } catch (err) {
        console.error('Error loading article:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadArticle();
  }, [location.pathname]);

  if (loading) {
    return (
      <S.PageContainer>
        <div className="flex justify-center items-center p-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
      </S.PageContainer>
    );
  }

  if (error) {
    return (
      <S.PageContainer>
        <div className="text-red-600 p-4">
          <h1 className="text-xl font-bold mb-2">Error</h1>
          <p>{error}</p>
          <button 
            onClick={() => navigate('/articles')}
            className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          >
            Back to Articles
          </button>
        </div>
      </S.PageContainer>
    );
  }

  if (!article) {
    return null;
  }

  const { metadata, content } = article;
  
  // Ensure all metadata values are strings or arrays of strings
  const safeMetadata = {
    title: metadata?.title ? String(metadata.title).trim() : '',
    description: metadata?.description ? String(metadata.description).trim() : '',
    keywords: metadata?.keywords 
      ? (Array.isArray(metadata.keywords) 
          ? metadata.keywords.map(k => String(k).trim()).join(', ')
          : String(metadata.keywords).trim())
      : '',
    canonical: metadata?.canonical ? String(metadata.canonical).trim() : ''
  };

  return (
    <S.PageContainer>
      <Helmet>
        {safeMetadata.title && <title>{safeMetadata.title}</title>}
        <meta property="og:type" content="article" />
        {safeMetadata.description && (
          <meta name="description" content={safeMetadata.description} />
        )}
         
        {safeMetadata.title && (
          [
            <meta property="og:title" content={safeMetadata.title} />,
            <meta name="twitter:title" content={safeMetadata.title} />
          ]
        )}
        
        {safeMetadata.description && (
          [
            <meta property="og:description" content={safeMetadata.description} />,
            <meta name="twitter:description" content={safeMetadata.description} />
          ]
        )}
        
        {safeMetadata.keywords && (
          <meta name="keywords" content={safeMetadata.keywords} />
        )}
        
        {safeMetadata.canonical && (
          <link rel="canonical" href={safeMetadata.canonical} />
        )}
      </Helmet>

      <ReactMarkdown 
        remarkPlugins={[remarkGfm]}
        components={{
          h1: ({ children }) => <S.PageTitle>{children}</S.PageTitle>,
          h2: ({ children }) => <S.SectionTitle>{children}</S.SectionTitle>,
          h3: ({ children }) => <S.SubSectionTitle>{children}</S.SubSectionTitle>,
          p: ({ children }) => <S.Paragraph>{children}</S.Paragraph>,
          a: ({ href, children }) => 
            href?.startsWith('/') ? 
              <S.StyledLink to={href}>{children}</S.StyledLink> :
              <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>
        }}
      >
        {content}
      </ReactMarkdown>
    </S.PageContainer>
  );
};

export default ArticlePage;