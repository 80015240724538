import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

export const PageTitle = styled.h1`
  color: #3498db;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
`;

export const SectionTitle = styled.h2`
  color: #2c3e50;
  font-size: 1.8rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #3498db;
  padding-bottom: 0.5rem;
`;

export const SubSectionTitle = styled.h3`
  color: #2c3e50;
  font-size: 1.6rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #3498db;
  padding-bottom: 0.5rem;
`;

export const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #34495e;
  margin-bottom: 1rem;
`;

export const StyledLink = styled(Link)`
  color: #3498db;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;

  &:hover {
    color: #e74c3c;
  }
`;
