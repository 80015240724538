import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import CountryPage from './pages/CountryPage';
import About from './pages/About';
import WhatIsDUNS from './pages/WhatIsDUNS';
import Benefits from './pages/Benefits';
import HowToGet from './pages/HowToGet';
import Articles from './pages/Articles';
import ArticlePage from './pages/ArticlePage';
import DUNSLookup from './components/DUNSLookup';
import AdComponent from './components/AdComponent';
import styles from './App.module.css';
import CountryList from './pages/CountryList';
import useCurrentURL from './hooks/useCurrentURL';
import ScrollToTop from './components/ScrollToTop';

const CanonicalLink = () => {
  const currentURL = useCurrentURL();

  //since we executing JS here. For the JS-enabled clients, we'll revmoe the default tags... They're only there for social sharing
  var defaultTitle = document.getElementsByTagName('title')[0];
  if (defaultTitle) {
    defaultTitle.remove();
  }
  var defaultRelLinks = document.getElementsByTagName('link');
  if (defaultRelLinks)
  {
    Array.from(defaultRelLinks).forEach(link => {
      // Do something with each link element
      var linkRel = link.getAttribute('rel');
      if (linkRel&& (linkRel === 'og:url' || linkRel === 'twitter:url' || linkRel === 'canonical')) {
        link.remove();
      }
    });
  }
  var defaultMeta = document.getElementsByTagName('meta');
  if (defaultMeta)
  {
    Array.from(defaultMeta).forEach(meta => {
      // Do something with each link element
      var metaProperty = meta.getAttribute('property');
      var metaName = meta.getAttribute('name');
      var metaHelmet = meta.getAttribute('data-react-helmet');
      if ((metaProperty && (metaProperty === 'og:description' || metaProperty === 'twitter:description' 
        || metaProperty === 'og:title' || metaProperty === 'twitter:title'))
        || (metaName && metaName === 'description' )) {

          if (!metaHelmet) {
            meta.remove();
          }
      }
    });
  }

  return (
    <Helmet>
      <link rel="canonical" href={currentURL} />
      <link rel="og:url" href={currentURL} />
      <link rel="twitter:url" href={currentURL} />
    </Helmet>
  );
};

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <div className={styles.app}>
        <CanonicalLink />
        <Header />
        <main className={styles.mainContent}>
          <AdComponent position="top" adId="7047673042" />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/what-is-duns" element={<WhatIsDUNS />} />
            <Route path="/benefits" element={<Benefits />} />
            <Route path="/how-to-get" element={<HowToGet />} />
            <Route path="/country-info/:country" element={<CountryPage />} />
            <Route path="/countries" element={<CountryList />} />
            <Route path="/duns-lookup" element={<DUNSLookup />} />
            <Route path="/all-articles" element={<Articles />} />
            <Route path="/articles/*" element={<ArticlePage />} /> {/* New dynamic route */}
          </Routes>
          <AdComponent position="bottom" adId="7650553099" />
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;